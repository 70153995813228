import React, { useCallback } from "react";
import ShopCategoriesBreadcrumbQuery from "../../categories/components/ShopCategoriesBreadcrumbQuery";
import ShopNavItemsQuery from "../../categories/components/ShopNavItemsQuery";
import type { BackgroundColors } from "../../layout/components/PageLayout";
import PageLayout from "../../layout/components/PageLayout";
import Breadcrumbs from "../../navigation/components/Breadcrumbs";
import type { NavItemsQueryType } from "../../navigation/types";

export interface ShopPageLayoutProps {
  postBreadcrumContent?: any;
  fullWidthHeader?: any;
  backgroundColor?: BackgroundColors;
  renderBreadcrumb?: () => React.ReactNode;
  showCurrentBreadcrumb?: boolean;
  maxBreadcrumbsLength?: number;
  showLastVisitedProducts?: boolean;
  NavItemsQuery?: NavItemsQueryType; // only override if you know what you do
}

const ShopPageLayout: React.FC<ShopPageLayoutProps> = ({
  renderBreadcrumb,
  showCurrentBreadcrumb,
  maxBreadcrumbsLength,
  ...props
}) => {
  const renderDefaultBreadcrump = useCallback(
    () => (
      <>
        <Breadcrumbs
          BreadcrumbQuery={ShopCategoriesBreadcrumbQuery}
          showCurrent={showCurrentBreadcrumb}
          maxLength={maxBreadcrumbsLength}
        />
        {props.postBreadcrumContent}
      </>
    ),
    [props.postBreadcrumContent, maxBreadcrumbsLength, showCurrentBreadcrumb],
  );

  return (
    <PageLayout
      NavItemsQuery={ShopNavItemsQuery}
      renderBreadcrumb={
        renderBreadcrumb ? renderBreadcrumb : renderDefaultBreadcrump
      }
      {...props}
    />
  );
};

export default ShopPageLayout;
