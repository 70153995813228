import type { BreadcrumbQueryType } from "../../navigation/types";

import DefaultBreadcrumbQuery from "../../navigation/components/DefaultBreadcrumbQuery";
import useAssortmentNavItem from "../hooks/useAssortmentNavItem";
import { SHOP_PATH } from "../../../config/locations";

const AssortmentBreadcrumbQuery = ({ path, children }) => {
  const { assortmentNavItem } = useAssortmentNavItem({
    path,
  });

  if (assortmentNavItem) {
    return children(assortmentNavItem);
  }
  return null;
};
const ShopCategoriesBreadcrumbQuery: BreadcrumbQueryType = ({
  routePath,
  path,
  slug,
  children,
}) => {
  if (slug !== "shop") {
    return <AssortmentBreadcrumbQuery path={path} children={children} />;
  }
  return (
    <DefaultBreadcrumbQuery
      routePath={routePath}
      path={path}
      slug={slug}
      children={children}
    />
  );
};
export default ShopCategoriesBreadcrumbQuery;
