import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import useMediaQueries from "../../../hooks/useMediaQueries";

import { selectColor, selectFont } from "../../../utils/themeUtils";
import HorizontalScroller from "../../layout/components/HorizontalScroller";
import useBreadcrumbPaths from "../hooks/useBreadcrumbPaths";
import type { BreadcrumbQueryType } from "../types";
import LinkWithActive from "./LinkWithActive";

const Base = styled(HorizontalScroller).attrs({ fadeColor: "white" })`
  ${selectFont("breadCrumbs")};
`;

const A = styled.a`
  color: ${selectColor("petrol")};
  text-decoration: none;
  white-space: nowrap;
`;
const Separator = styled.span.attrs({ children: "/" })`
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: ${selectColor("textBlack")};
`;

export interface BreadcrumbsProps {
  style?: React.CSSProperties;
  className?: string;
  BreadcrumbQuery: BreadcrumbQueryType;

  showCurrent?: boolean;
  maxLength?: number;
}

const Breadcrumb = ({ path, slug, routePath, BreadcrumbQuery }) => (
  <BreadcrumbQuery path={path} slug={slug} routePath={routePath}>
    {({ href, path: customPath, title }) => (
      <LinkWithActive href={href} as={customPath || path}>
        <A>{title}</A>
      </LinkWithActive>
    )}
  </BreadcrumbQuery>
);

const TruncationDots = styled(A).attrs({
  children: "...",
})`
  cursor: pointer;
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  style,
  className,
  BreadcrumbQuery,

  showCurrent = false,
  maxLength = null,
}) => {
  const { asPath } = useRouter() ?? {};

  const [showTruncation, setShowTruncation] = useState(false);
  // use on route changes

  const allCrumbs = useBreadcrumbPaths();
  const crumbsWithoutcurrent = showCurrent ? allCrumbs : allCrumbs.slice(0, -1);
  const crumbs =
    maxLength !== null && maxLength >= 0
      ? crumbsWithoutcurrent.slice(0, maxLength)
      : crumbsWithoutcurrent;

  const { desktop } = useMediaQueries();

  const isServer = typeof window === "undefined";
  const truncate = !isServer && !desktop && crumbs.length > 2;

  useEffect(() => {
    setShowTruncation(true);
  }, [asPath]);

  return (
    <Base style={style} className={className}>
      {crumbs.map(({ path, slug }, index) => {
        const isFirst = index === 0;
        const isLast = index === crumbs.length - 1;

        if (showTruncation && truncate && !isFirst && !isLast) {
          if (index === 1) {
            return (
              <Fragment key={index}>
                <TruncationDots
                  key={index}
                  onClick={() => setShowTruncation(false)}
                />
                <Separator />
              </Fragment>
            );
          }
          return null;
        }
        return (
          <Fragment key={index}>
            <Breadcrumb
              BreadcrumbQuery={BreadcrumbQuery}
              path={path}
              slug={slug}
              routePath={asPath}
            />
            {index < crumbs.length - 1 ? <Separator /> : null}
          </Fragment>
        );
      })}
    </Base>
  );
};

export default React.memo(Breadcrumbs);
