import React from "react";
import styled from "styled-components";
import Link from "next/link";

const Base = styled.div``;

export interface PageNotFoundBoxProps {
  style?: React.CSSProperties;
  className?: string;
}

const PageNotFoundBox: React.FC<PageNotFoundBoxProps> = ({
  style,
  className,
}) => (
  <Base style={style} className={className}>
    <Link href="/">
      <a>
        <p>404 - page not found</p>
      </a>
    </Link>
  </Base>
);

export default PageNotFoundBox;
