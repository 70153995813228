import React, { useEffect } from "react";
import { useTranslation } from "../hooks/useTranslation";
import ShopNavItemsQuery from "../modules/categories/components/ShopNavItemsQuery";
import { useInlineError } from "../modules/core/components/InlineError";
import PageNotFoundBox from "../modules/layout/components/PageNotFoundBox";
import ShopPageLayout from "../modules/shop/components/ShopPageLayout";
import usePageBaseProps from "../modules/navigation/hooks/usePageBaseProps";
import ContentPage from "../modules/content/components/ContentPage";

export function Error(props) {
  const { statusCode, err, errorMessage } = props;
  const { loading, page } = usePageBaseProps("/404");
  const { t } = useTranslation("common");
  const { inlineErrorElement, showInlineErrorAndLog, hideInlineError } =
    useInlineError();

  // Error objects cannot be properly rehydrated on the client, so we need to set the error message manually for server-side errors
  if (!err?.message && errorMessage) {
    err.message = errorMessage;
  }

  useEffect(() => {
    if (err) {
      showInlineErrorAndLog(err);
    }
  }, [err, showInlineErrorAndLog]);

  if (loading) {
    return null;
  }

  if (page && statusCode === 404) {
    return <ContentPage path="/404" />;
  }

  return (
    <ShopPageLayout NavItemsQuery={ShopNavItemsQuery}>
      {statusCode === 404 ? (
        <PageNotFoundBox />
      ) : (
        <>
          <p>
            {inlineErrorElement}
            <br />
            <br />
            {statusCode
              ? `[Env: Server, Status Code: ${statusCode}]`
              : "[Env: Client]"}
          </p>
        </>
      )}
    </ShopPageLayout>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  // Error objects cannot be properly rehydrated on the client, so we need to pass the error message separately for server-side errors
  const errorMessage = err?.message;
  return { statusCode, err, errorMessage };
};

export default Error;
