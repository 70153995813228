import { useTranslation } from "../../../hooks/useTranslation";
import type { BreadcrumbQueryType } from "../types";

const DefaultBreadcrumbQuery: BreadcrumbQueryType = ({
  slug,
  path,
  children,
}) => {
  const { t } = useTranslation("navigation");
  const title = t([`${slug}.navigationTitle`, `${slug}.title`]);
  return children({ href: path, title, path, slug, type: "internal" });
};

export default DefaultBreadcrumbQuery;
